<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Filter -->
        <b-row>
          <b-col cols="12">
            <div
              style="z-index: 1"
            >
              <label class="mr-1">Localidad: </label>
              <v-select
                v-model="localidad"
                label="name"
                :options="localidades"
                @input="filterData"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        class="mx-2 mb-2"
      >
        <b-row>
          <b-col cols="12">
            <list-table-data
              :table-columns="tableColumns"
              :table-data="items"
              :show-icon-new="false"
              :redirect-edit="redirectEdit"
              :redirect-show="redirectShow"
              :origen="catalogoName"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import ListTableData from '@/components/ListTableData.vue'
import { getPostesCompany, getPostesLevantamiento } from '@/utils/postes'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { getLocalidades } from '@/utils/localizacion'

export default {
  components: {
    ListTableData,
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catalogoName: 'Puntos de Iluminiación',
      redirectEdit: '',
      redirectShow: '',
      items: [],
      user: JSON.parse(localStorage.getItem('userData')),
      localidad: {},
      localidades: [],
      tableColumns: [
        {
          label: 'Número de Poste',
          field: 'numeroPoste',
          tdClass: 'text-center',
        },
        {
          label: 'Levantamiento',
          field: 'levantamiento',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  async beforeMount() {
    try {
      this.localidades = await getLocalidades(undefined, this.user.company.municipio)
      this.items = []
      if (this.localidades === null) this.localidades = []
      if (this.localidades.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.localidad = this.localidades[0]
        await this.loadPostes(this.localidades[0])
      }
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.localidades = []
      this.items = []
    }
  },
  methods: {
    async loadPostes(filter) {
      if (filter === null) this.items = await getPostesLevantamiento(this.user.company, 0)
      else this.items = await getPostesLevantamiento(this.user.company, filter.id)
      if (this.items === null) this.items = []
    },
    async filterData(value) {
      await this.loadPostes(value)
    },
  },
}
</script>

<style scoped>

</style>
